<template>
  <div @mousedown="close" v-if="value" class="modal" tabindex="-1">
    <div @mousedown.stop class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Exercise history</h5>
          <button @click="close" type="button" class="btn-close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="search" :class="isLoading ? 'is-busy' : ''">
            <div class="input-group">
              <input
                v-model="keyword"
                ref="keyword"
                type="text"
                class="form-control"
                placeholder="Insert keyword..."
              >
              <IconButton
                @click="search"
                :icon="'search'"
                :disabled="isSubmitDisabled"
                class="btn btn-primary"
              ></IconButton>
            </div>
          </form>

          <div
            v-if="exercises !== null"
            :class="{ 'is-busy': isLoading }"
            class="mt-3"
          >
            <div :class="{ 'show-all': showAll }" class="list-group list-group-flush">
              <div v-if="!exercises.length" class="list-group-item">No history.</div>
              <div
                v-for="(exercise, exerciseIndex) in exercises"
                :key="`key-${exerciseIndex}`"
                class="list-group-item"
              >
                <div>
                  <div class="d-flex align-items-baseline">
                    <div class="flex-grow-1">{{ exercise.name }}</div>
                    <div class="small text-muted fst-italic ms-3">
                      {{ exercise.date | dateRelative }}
                    </div>
                  </div>
                </div>
                <ExerciseSetRep
                  :sets="$options.filters.exerciseSetParsed(exercise)"
                  class="mt-1"
                ></ExerciseSetRep>
                <TextView
                  v-if="exercise.note && exercise.note !== ''"
                  :text="exercise.note"
                  :useQuote="true"
                  class="small mt-2"
                ></TextView>
              </div>
            </div>
            <div v-if="exercises && exercises.length > 5 && !showAll" class="mt-3">
              <button @click="more" class="btn btn-primary w-100">Show more</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { httpGet } from '@/core/http';

export default {
  name: 'ExerciseHistoryModal',
  components: {
    IconButton: () => import('@/components/button/IconButton'),
    ExerciseSetRep: () => import('@/components/ExerciseSetRep'),
    TextView: () => import('@/components/TextView'),
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    exercise: {
      type: Object,
      required: true,
    },
    userId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState('auth', ['authUser']),
    isSubmitDisabled() {
      return this.keyword === '';
    },
  },
  methods: {
    async search() {
      this.isLoading = true;
      try {
        const { keyword } = this;
        const userId = this.userId ? this.userId : this.authUser.userId;
        const res = await httpGet('/exercise/history', { name: keyword, userId });
        this.exercises = res.data;
        this.showAll = false;
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isLoading = false;
      }
    },
    more() {
      this.showAll = true;
    },
    close() {
      this.$emit('close');
    },
  },
  data() {
    return {
      isLoading: false,
      showAll: false,
      keyword: '',
      exercises: null,
    };
  },
  mounted() {
    this.keyword = this.exercise.name;
    this.search();
  },
};
</script>

<style lang="scss" scoped>
.list-group {
  > .list-group-item:nth-child(n+6) {
    display: none;
  }

  &.show-all {
    > .list-group-item:nth-child(n+6) {
      display: block;
    }
  }
}
</style>
